<template>
  <div
    v-if="notificationObj"
    :class="[!notificationObj.read ? 'border-blue-500 bg-blue-900/25' : 'border-gray-500 bg-gray-900/25']"
    class="flex h-16 flex-col justify-center border-l-2 p-1"
  >
    <div class="flex items-center justify-between px-1">
      <div class="flex flex-col items-baseline">
        <a
          class="line-clamp-2 break-all text-xs text-white"
          :href="notification.url"
          :title="notification.message"
          :target="['News', 'SEC'].includes(notification.alert.entity) ? '_blank' : ''"
        >
          {{ notification.message }}
        </a>
        <span class="mt-px text-[0.6rem] text-gray-400">
          {{ minimalTimeWithinToday(notification.created_at) }}
        </span>
      </div>
      <div class="flex items-center space-x-0.5">
        <DropdownTableCell
          :items="dropdownItems"
          :cell-info="notification"
          :x-anchor="'left'"
          :global-emit="false"
          @read="markNotificationRead()"
          @delete="deleteNotification()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import DropdownTableCell from '@/components/dropdown/DropdownTableCell.vue';
import { minimalTimeWithinToday } from '@/composeables/filters';
import useHttp from '@/composeables/http';
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';

const $store = useStore();
const $http = useHttp();
const $emit = defineEmits(['markAsRead']);

const props = defineProps({
  notification: {
    type: Object
  }
});

onMounted(() => {
  notificationObj.value = props.notification;
});

const notificationObj = ref({});

const dropdownItems = computed(() => {
  let items = [];
  if (props.notification.read) {
    items.push({ id: 'unread', text: 'Mark Unread', event: 'read' });
  } else {
    items.push({ id: 'read', text: 'Mark Read', event: 'read' });
  }
  items.push({ id: 'delete', text: 'Delete', event: 'delete' });
  return items;
});

function markNotificationRead() {
  $http
    .post('/notifications/mark_read', {
      id: props.notification.id
    })
    .then(() => {
      $store.commit('changeUserNotificationStatus', {
        id: props.notification.id,
        status: !props.notification.read
      });
      $emit('markAsRead', false);
    });
}

function deleteNotification() {
  $http.post('/notifications/delete', { id: props.notification.id }).then(() => {
    $store.commit('removeUserNotification', props.notification.id);
  });
}
</script>
